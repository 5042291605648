import styled from "styled-components";
import {
  DEVICE,
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
} from "../../utilities/constants";

export const Container = styled.div`
  padding: 20px;
  border-radius: 12px;
  opacity: 1;
  min-height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT});
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  @media ${DEVICE.tablet} {
    padding: 10px;
    margin: 10px;
    height: auto;
  }
`;
export const Wrapper = styled.div`
  display: flex;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 48px;
  align-items: center;
  justify-content: space-between;
`;
export const Header = styled.div`
  font-size: 24px;
  color: #0099CC;
  font-weight: 600;
  margin-right: 150px;
  display: flex;
  width: 100%;
  @media ${DEVICE.mobileL} { 
    font-size: 22px;
  }
`;
export const SearchInput = styled.input`
  font-size: 14px;
  outline: none;
  flex: 1;
  background: transparent;
  height: 35px;
  border: none;
`;
export const HeaderLeft = styled.div``;
export const HeaderRight = styled.div`
  display: flex;
  align-self: flex-end;
  justify-content: space-between;
  align-items: center;
  @media ${DEVICE.tablet} {
    display: flex;
    align-self: center;
  }
`;

export const SearchButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;
export const SearchImage = styled.img`
  width: 25px;
  height: 25px;
`;
export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #ebf7fb 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 5px 12px;
  width: 400px;
  height: 35px;
  margin-top: 10px;
  border-style: hidden;
  @media ${DEVICE.tablet} {
    width: 100%;
    align-self: center;
    display: flex;
  }
`;
export const ActionImage = styled.img`
  margin-right: 5px;
`;
export const ProfileImage = styled.img`
  margin-right: 10px;
`;
export const EmptyListMessage = styled.div`
  margin-top: 10px;
  color: red;
  text-align:center;
  @media ${DEVICE.tablet} {
    text-align: center;
    align-self: center;
    justify-content: center;
  }
`;
export const WrappedListValue = styled.div`
  width: 40px;
  height: 25px;
  border: 1px solid #d7eff7;
  border-radius: 5px;
  color: #0099cc;
  font-weight: bold;
  background-color: #ebf7fb;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 5px;
`;
export const EditDeleteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`;
export const FullNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  font-size: 14px;
`;
export const FullName = styled.span`
  color: #0099cc;
  font-weight: 600;
  font-size: 16px;
  margin-right: 10px;
`;
export const PhoneNo = styled.span`
  color: #000000;
  font-size: 14px;
`;
export const DOBLabel = styled.span`
  color: #808080;
  font-size: 14px;
  margin-right: 10px;
  font-weight: 600;
`;
export const DOBVal = styled.span`
  color: #808080;
  font-size: 14px;
`;
export const PatientContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  @media ${DEVICE.tablet} {
    justify-content: center;
  }
`;
export const PatientCard = styled.div`
 width: 330px;
  min-height: 129px;
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
`;
export const ProfileSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 24px;

`;
export const ProfileImageIcon = styled.img``;
export const PatientInfo = styled.div`
  @media ${DEVICE.mobileL} {
    margin: 0;
  }
`;
export const PhoneNumber = styled.div`
  color: #00000;
  font-size: 14px;
`;
export const Name = styled.div`
  color: #0099cc;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 4px;
  font-size: 16px;
`;
export const ArrowImage = styled.img`
  cursor: pointer;
  float: right;
  @media ${DEVICE.tablet} {
    display: none;
  }
`;
export const DetailSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  padding: 16px 0;
  @media ${DEVICE.tablet} {
    flex-direction: column;
  }
`;
export const DetailItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
`;
export const EmailSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 12px;
`;
export const EmailIcon = styled.img``;
export const Email = styled.div`
  font-size: 14px;
  color: #808080;
`;
export const GenderImage = styled.img``;
export const DobImage = styled.img``;
export const GenderText = styled.div`
  color: #808080;
`;
export const DobText = styled.div`
  color: #808080;
`;
export const DobDate = styled.span``;
export const DobYears = styled.span`
  color: #808080;
`;

export const SubmitButton = styled.button`
    background-color: #7ABA56;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 13px;
    min-width: 150px;
    height: 45px;
    border: none;
    cursor: pointer;

    &:disabled {
        background-color: #CCCCCC;
        color: #666666;
        cursor: not-allowed;
    }

    @media ${DEVICE.mobileL} {
      display: none;
    }
`;

export const AccessCodeBtn = styled.button`
    background-color: #7ABA56;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 13px;
    padding: 10px;
    border: none;
    cursor: pointer;
    display: none;
    &:disabled {
      background-color: #CCCCCC;
      color: #666666;
      cursor: not-allowed;
  }
  @media ${DEVICE.mobileL} {
    display: block;
   }
`

export const InnerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
`;
export const EmailText = styled.div`
    font-size: 14px !important;
    color: #000000;
    max-width: 220px;   
    overflow-wrap: break-word;
    word-wrap: break-word;
    white-space: normal;    
`;

export const EmailItem = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;
  margin-top: 3px;
`;

export const EmailImage = styled.img`
  height: 14px;
  margin-top: 4px;
`;